import {timeouts} from '../parts/vars.js';

/**
 * Shuffles the elements in the given array.
 *
 * @param {Array} array - The array to be shuffled.
 * @return {Array} The shuffled array.
 */
export function shuffle(array) {
	let currentIndex = array.length;
	let randomIndex;
	// While there remain elements to shuffle.
	while (currentIndex > 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;
		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}
	return array;
}

/**
 * Wraps non-whitespace charachters in text nodes within the given
 * HTML element with a <span> containing the provided class.
 *
 * @param {Node} node - The HTML element to wrap charachters within.
 * @param {string} [htmlClass=''] - The HTML class to apply to the span element.
 * @return {undefined} This function does not return a value.
 */
export function wrapText(node, htmlClass = '') {
	// if (!/^[a-z_-][a-z\d_-]*$/i.test(htmlClass)) {
	// 	console.log('Invalid class name provided for wrapText() function:', htmlClass);
	// 	return;
	// }
	node.childNodes.forEach((item) => {
		// nodeType == 3 is for text nodes
		if (item.nodeType == 3) {
			const text = item.textContent.split('');
			const newNode = document.createElement('span');

			const newHTML = text.map(letter => {
				if ( !letter.match(/\s/g)) {
					return `<span class="${htmlClass}">${letter}</span>`;
				}
				return letter;
			}).join('');

			newNode.innerHTML = newHTML;
			item.parentNode.insertBefore(newNode, item);
			item.parentNode.removeChild(item);
		}
		else {
			wrapText(item, htmlClass);
		}
	})
}


/**
 * Function to create a rotor effect on the given element using the provided options.
 *
 * @param {HTMLElement} element - The HTML element on which the rotor effect will be applied.
 * @param {Object} options - The options for customizing the rotor effect.
 * @param {number} options.speed - The speed at which the rotor effect will rotate the items (default: 5000).
 * @param {boolean} options.clickToAdvance - Whether clicking the element should advance the rotor effect (default: false).
 */
export function rotor(element, options) {
	options = {
		speed: 5000,
		clickToAdvance: false,
		...options
	}
	const items = element.children;

	if (items.length > 1) {
		element.classList.add('rrotor');
		let currentItem = element.querySelector('.rrotor--current');
		let currentIndex = currentItem ? Array.from(items).indexOf(currentItem) : 0;

		items[currentIndex].classList.add('rrotor--current');
		items[currentIndex + 1].classList.add('rrotor--next');
		items[items.length - 1].classList.add('rrotor--previous');

		function advanceRotor() {
			items[currentIndex - 1 >= 0 ? currentIndex - 1 : items.length - 1].classList.remove('rrotor--previous');
			items[currentIndex].classList.add('rrotor--previous')
			items[currentIndex].classList.remove('rrotor--current');
			currentIndex = currentIndex + 1 >= items.length ? 0 : currentIndex + 1;
			items[currentIndex].classList.add('rrotor--current');
			items[currentIndex].classList.remove('rrotor--next');
			items[currentIndex + 1 >= items.length ? 0 : currentIndex + 1].classList.add('rrotor--next');
		}

		// let rotorTimeout = timeouts.push(setTimeout(function newRotor() {
		// 	advanceRotor();
		// 	rotorTimeout = timeouts.push(setTimeout(newRotor, speed));
		// }, speed));

		let rotorTimeout;
		function newRotor() {
			rotorTimeout = setTimeout(() => {
				advanceRotor();
				rotorTimeout = setTimeout(newRotor, options.speed);
			}, options.speed);
			timeouts.push(rotorTimeout);
		}
		newRotor();

		if (options.clickToAdvance === true) {
			element.classList.add('rrotor_clickable');

			element.addEventListener('click', () => {
				clearTimeout(rotorTimeout);
				advanceRotor();
				newRotor();
			})
		}
	}
}
