let wrapperStyles = window.getComputedStyle(document.body);

export const CSSVAR = {
	speedShift: parseInt(wrapperStyles.getPropertyValue('--speed-shift'), 10),
	speedInview: parseInt(wrapperStyles.getPropertyValue('--speed-inview'), 10),
	speedShiftOutwards: parseInt(wrapperStyles.getPropertyValue('--speed-shift-outwards'), 10),
	speedWebHeaderAnimation: parseInt(wrapperStyles.getPropertyValue('--speed-web-header-animation'), 10),
	breakpointMobile: parseInt(wrapperStyles.getPropertyValue('--breakpoint-mobile'), 10),
	breakpointLarge: parseInt(wrapperStyles.getPropertyValue('--breakpoint-large'), 10),
}

export const timeouts = [];


// const lightSwitch = document.querySelector('#lightswitch');
