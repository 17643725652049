import { CSSVAR, timeouts } from './parts/vars.js';

// ================================================================================================= MODULES
import * as werwiewasExports from './sections/werwiewas.js';
Object.entries(werwiewasExports).forEach(([name, exported]) => window[name] = exported);

import * as logoExports from './sections/logos.js';
Object.entries(logoExports).forEach(([name, exported]) => window[name] = exported);

import * as printExports from './sections/print.js';
Object.entries(printExports).forEach(([name, exported]) => window[name] = exported);

import * as eventsExports from './sections/events.js';
Object.entries(eventsExports).forEach(([name, exported]) => window[name] = exported);

import * as webExports from './sections/web.js';
Object.entries(webExports).forEach(([name, exported]) => window[name] = exported);

import * as impressumExports from './sections/impressum.js';
Object.entries(impressumExports).forEach(([name, exported]) => window[name] = exported);

import CSlider from './parts/cslider.js';


let urlHash = window.location.hash;
export const hashTargets = [];
for (const element of document.querySelectorAll('r-target')) {
	hashTargets.push( '#' + element.getAttribute('id') );
}





// ================================================================================================= INIT FUNCTIONS
const docTitle = document.title;
setTitle();

// INITIAL STATE (with urlHash)
if (hashTargets.includes(urlHash)) {
	let hashClass = 'target_' + urlHash.slice(1);
	let visitedClass = 'visited_' + urlHash.slice(1);
	document.body.classList.add(hashClass, visitedClass, 'inside', 'shifted', 'animation_reset', 'init_shifted');
	// document.querySelector('r-darkness').remove();
}

document.body.classList.remove('init');

// REMOVE INIT CLASSES AFTER SHORT DELAY TO SUPPRESS CSS TRANSITIONS ON LOAD
requestAnimationFrame(() => {
	document.body.classList.remove('init_none');
	initImpressum();
	observeWerwiewas();
	initLogos();
	setAnimationLogos();
	observePrint();
	initEvents();
	setAnimationEvents();
	observeEvents();
	initWebShiftAnimation();
	initWebButtonNav();
	resetWebMedia();
	setWebMediaOffset();
	observeWeb();
	shuffleWebLetters();

	requestAnimationFrame(() => {
		document.body.classList.remove('init_black');
		window.plakatSlider = new CSlider('#slider_plakate', {
			transitionSpeed: 700,
			buttonTextNext: '<svg width="100%" height="100$" viewBox="0 0 100 100"><path class="arrow_inner" d="M24.03 81.38l31.393-31.379-31.375-31.386L36.326 6.343l43.643 43.663-43.665 43.651z"/><path class="arrow_outer" d="M36.324 12l37.988 38.006L36.305 88l-6.617-6.619L61.08 50.002 29.705 18.616 36.324 12M36.327.686l-5.658 5.656-6.619 6.616-5.658 5.656 5.656 5.658L49.766 50 24.032 75.723l-5.658 5.656 5.656 5.658 6.617 6.619 5.656 5.657 5.658-5.655 38.008-37.995 5.657-5.655-5.655-5.658L41.982 6.344 36.327.686z"/></svg>',
			buttonTextPrev: '<svg width="100%" height="100%" viewBox="0 0 100 100"><path class="arrow_inner" d="M20.031 49.993l43.665-43.65L75.97 18.619 44.578 49.998l31.374 31.387-12.277 12.272z"/><path class="arrow_outer" d="M63.695 12l6.617 6.619-31.391 31.378 31.374 31.387L63.676 88 25.688 49.994 63.695 12M63.697.686l-5.658 5.656-38.007 37.994-5.658 5.656 5.655 5.657 37.988 38.006 5.655 5.658 5.658-5.655 6.619-6.616 5.658-5.655-5.655-5.658-25.718-25.73 25.733-25.723 5.659-5.656-5.657-5.658-6.617-6.619L63.697.686z"/></svg>',
			waitForMedia: true,
			onReady() {
				setAnimationPrint();
			}
		});
	})
});



// ================================================================================================= SHIFT FUNCTIONS
onhashchange = () => {
	urlHash = window.location.hash;
	shift();
	setAnimationWerwiewas();
	setAnimationLogos();
	setAnimationPrint();
	setAnimationEvents();
	shuffleWebLetters();
	setTitle();
}

function shift() {
	document.body.classList.remove('init_shifted');
	document.body.classList.add('nopointer');

	if (!hashTargets.includes(urlHash)) {
		shiftOutwards();
	}
	else {
		let hashClass = 'target_' + urlHash.slice(1);
		let visitedClass = 'visited_' + urlHash.slice(1);
		document.body.classList.add(hashClass, visitedClass, 'inside');

		setTimeout(() => {
			document.body.classList.add('shifted');
		}, CSSVAR.speedShift + 50);

		setTimeout(() => {
			document.body.classList.add('animation_reset');
		}, CSSVAR.speedShift + 350);															// remove magic number
	}

	setTimeout(() => {
		document.body.classList.remove('nopointer');
	}, CSSVAR.speedShift + 100);
}

function shiftOutwards() {
	document.body.classList.add('nopointer');
	document.body.classList.remove('animation_reset', 'inside');

	if (document.body.classList.contains('visited_events')) {
		document.body.classList.add('left_events');
	}

	for (const timeout of timeouts) {
		clearTimeout(timeout);
	}

	setTimeout(() => {
		hashTargets.forEach((target) => {
			let targetClass = 'target_' + target.slice(1);
			document.body.classList.remove(targetClass);
		});

		document.body.classList.remove('nopointer', 'shifted');
	}, CSSVAR.speedShiftOutwards + 100)
}

function setTitle() {
	let title = document.querySelector(`r-target[id="${urlHash.slice(1)}"]`)?.getAttribute('data-title');
	document.title = title ? title + ' | ' + docTitle : docTitle;
}


// =============================================================================================
// Recalculations on resize --------------------------------------------------------------------
// =============================================================================================
let resizeTimer;

window.addEventListener('resize', () => {
	clearTimeout(resizeTimer);

	resizeTimer = setTimeout(() => {
		setWebMediaOffset();
	}, 100);
});

