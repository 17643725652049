//========================================================================================================
//                                       LOGOS SECTION
//========================================================================================================
import {CSSVAR, timeouts} from '../parts/vars.js';
import {shuffle} from '../parts/tools.js';



function isSafari() {
	// Get the user-agent string
	let userAgentString = navigator.userAgent;

	// Detect Chrome
	let chromeAgent = userAgentString.indexOf("Chrome") > -1;

	// Detect Safari
	let safariAgent = userAgentString.indexOf("Safari") > -1;

	// Discard Safari since it also matches Chrome
	if ((chromeAgent) && (safariAgent)) safariAgent = false;

	return safariAgent;
}


export const logoItems = document.querySelectorAll('.logogrid--item');

if (!isSafari()) {
	logoItems.forEach(item => {
		item.addEventListener('mouseenter', () => {
			randomLogoOutline(item);
		});
	})
}


export function initLogos() {
	logoItems.forEach((item, index) => {
		const xTranslation = Math.random() * 1 - .5;
		const yTranslation = Math.random() * 1.5 - .75;

		item.style.setProperty('translate', `${xTranslation}em ${yTranslation}em`);

		if (!isSafari()) randomLogoOutline(item);
		// markEvenRows(item);
	});

	let itemArray = shuffle(Array.from(logoItems));

	itemArray.forEach((item, index) => {
		item.style.setProperty('--delay', index * 34 + CSSVAR.speedShift / 2 + 'ms');
	})

	document.querySelectorAll('.marquee').forEach(marquee);
}


export function markEvenRows(item) {
	if ( item.previousElementSibling?.classList.contains('logogrid--item')
	&& topY(item) - item.clientHeight > topY(item.previousElementSibling) ) {
		if (!item.previousElementSibling.classList.contains('even')) {
			item.classList.add('even');
		}
	}
	else if ( item.previousElementSibling?.classList.contains('even')) {
		item.classList.add('even');
	}
}


export function randomLogoOutline(item) {
	const rotation = Math.random() * 30 - 15;
	item.querySelector('r-outline-logo').style.setProperty('rotate', rotation+'deg');
	item.classList.add('twitch');

	let i = 1
	const twitch = setInterval(() => {
		item.querySelector('r-outline-logo').style.setProperty('clip-path',
			`polygon(
				0 ${Math.random() * 40}%,
				${Math.random() * 40 + 40}% 0,
				100% ${Math.random() * 40}%,
				${Math.random() * 50 + 50}% 100%,
				${Math.random() * 20}% ${Math.random() * 40 + 60}%
			)`
		);
		i++;
		if (i > 5) {
			clearInterval(twitch);
		}
	}, 40)

	setTimeout(() => {
		item.classList.remove('twitch');
	}, 450)
}


export function logoTwitch() {
	if (window.innerWidth > CSSVAR.breakpointMobile) {
		logoItems.forEach(item => {
			if (Math.random() < .01) {
				randomLogoOutline(item);
			}
		});
	}
}


export function marquee(element) {
	const text = element.children[0];
	const ratio = element.offsetWidth / text.offsetWidth;

	for (let i = 0; i < ratio * 3; i++) {
		element.appendChild(text.cloneNode(true));
	}
	element.style.setProperty('--marquee-ratio', Math.floor(ratio * 1000) / 1000);
}


export function letterGlow(element) {
	element.querySelectorAll('r-letter').forEach(function(letter) {
		timeouts.push(setTimeout(function repeat() {
			if (window.innerWidth > CSSVAR.breakpointMobile) {
				letter.classList.add('glow');
				setTimeout(() => {
					letter.classList.remove('glow');
				}, 1000)
			}
			timeouts.push(setTimeout(repeat, Math.random() * 15000 + 3000));
		}, Math.random() * 3000 + 1000))
	})
}


export function setAnimationLogos() {
	if (!isSafari()) {
		if (document.body.matches('.target_logos.inside')) {
			window.logoInterval = setInterval(logoTwitch, 250);
			letterGlow(document.querySelector('#section_logos--header'));
		}
		else if (typeof logoInterval !== 'undefined') {
			clearInterval(logoInterval);
		}
	}
}
