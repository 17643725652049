//========================================================================================================
//                                       EVENTS SECTION
//========================================================================================================
// import {CSSVAR, timeouts} from '../parts/vars.js';
import {rotor, wrapText} from '../parts/tools.js';
import { CSSVAR } from '../parts/vars.js';


export function initEvents() {
	wrapText(document.querySelector('.events_header--h2'), 'letter');
}


export function setAnimationEvents() {
	if (document.body.matches('.target_events.inside')) {
		setTimeout(() => {
			rotor(document.querySelector('#section_events .events_rotor'), {
				speed: 4000,
				clickToAdvance: true
			});
		}, CSSVAR.speedShift);

		document.querySelector('video.active')?.play();
	}
	else {
		document.querySelector('video.active')?.pause();
	}
}


export function observeEvents() {
	const viewOptions = {
		threshold: .8
	}

	const viewCallback = (entries, observer) => {
		entries.forEach(entry => {
			if (entry.intersectionRatio > viewOptions.threshold) {
				entry.target.classList.remove('waiting');
				entry.target.classList.add('active');
				entry.target.play();
				viewObserver.unobserve(entry.target);
			}
		})
	}

	const viewObserver = new IntersectionObserver(viewCallback, viewOptions);

	viewObserver.observe(document.querySelector('#video_ellwangenimsommer'));
}

