//========================================================================================================
//                                       WERWIEWAS SECTION
//========================================================================================================
import { CSSVAR } from "../parts/vars.js";

// Scrolleffekte -------------------------------------------------------------------------------
export function observeWerwiewas() {
	document.querySelectorAll('#section_werwiewas .waiting').forEach(element => {
		let viewOptions = {};

		function setViewOptions() {
			if(element.classList.contains('werwiewas_heading')) {
				viewOptions.rootMargin = `0px 0px ${Math.min(window.innerHeight * .33, Math.max(window.innerHeight - element.getBoundingClientRect().height, 0)) * -1}px 0px`;
				viewOptions.threshold = Math.min(1, window.innerHeight / element.getBoundingClientRect().height - .05);
			}
			if(element.classList.contains('werwiewas_flex--item')) {
				viewOptions.rootMargin = `0px 0px ${Math.min(window.innerHeight * .167, Math.max(window.innerHeight - element.getBoundingClientRect().height, 0)) * -1}px 0px`;
				viewOptions.threshold = .75;
			}
		}
		setViewOptions();

		const viewCallback = (entries, observer) => {
			entries.forEach(entry => {
				if (entry.intersectionRatio >= viewOptions.threshold) {
					entry.target.classList.remove('waiting');
					observer.unobserve(entry.target);

					const next = entry.target.nextElementSibling;
					if (next?.classList.contains('werwiewas_section')) {
						next.querySelectorAll('.waiting').forEach((item, index) => {
							item.classList.remove('waiting');
							if (item.classList.contains('werwiewas_flex--item')) {
								item.style.setProperty('--delay', index * 100 + CSSVAR.speedInview / 3 + 'ms')
							}
							observer.unobserve(item);
						})
					}
				}
			});
		};

		let viewObserver = new IntersectionObserver(viewCallback, viewOptions);
		viewObserver.observe(element);

		window.addEventListener('resize', () => {
			viewObserver.unobserve(element);
			setViewOptions();
			viewObserver = new IntersectionObserver(viewCallback, viewOptions);
			viewObserver.observe(element);
		});
	});

	new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if (entry.intersectionRatio >= 1) {
				entry.target.classList.remove('h3_waiting');
				observer.unobserve(entry.target);
			}
		});
	}, { threshold: 1 } ).observe(document.querySelector('#section_werwiewas .h3_waiting'));
}


export function setAnimationWerwiewas() {
	if (document.body.matches('.target_werwiewas')) {
		setTimeout(() => {
			document.querySelector('#wer_heading').classList.remove('waiting');
		}, CSSVAR.speedShift / 10);
		setTimeout(() => {
			document.querySelectorAll('#wer_section .waiting').forEach(element => {
				element.classList.remove('waiting');
			})
		}, CSSVAR.speedShift / 3);
	}
}