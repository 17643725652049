//========================================================================================================
//                                       PRINT SECTION
//========================================================================================================
import {timeouts} from '../parts/vars.js';
import {rotor} from '../parts/tools.js';

export function setAnimationPrint() {
	if (document.body.matches('.target_print.inside')) {
		plakatSlider.autoplayStart();

		const rotors = document.querySelectorAll('#section_print .beer_rotor');

		rotors.forEach((item, index) => {
			timeouts.push( setTimeout(() => {
				rotor(item, {
					speed: 3000
				});
			}, index * 150) );
		});
	}
	else {
		plakatSlider.autoplayStop();
	}
}

export function observePrint() {
	let viewOptions = {
		threshold: .4,
	}

	const viewCallback = (entries, observer) => {
		entries.forEach(entry => {
			if (entry.intersectionRatio >= viewOptions.threshold) {
				entry.target.classList.remove('waiting');
			}
		})
	}

	const viewObserver = new IntersectionObserver(viewCallback, viewOptions);

	document.querySelectorAll('#section_print .waiting').forEach(element => {
		viewObserver.observe(element);
	})
}