//========================================================================================================
//                                       WEB SECTION
//========================================================================================================
import {CSSVAR} from '../parts/vars.js';
import {shuffle, wrapText} from '../parts/tools.js';

export function observeWeb() {
	let webStoryOptions = {};
	let webObserver;
	const webStoryItems = document.querySelectorAll('.web_story--item');
	const webMedia = document.querySelector('.web_media');
	const webMediaHeight = webMedia.getBoundingClientRect().height;
	const webMediaItems = document.querySelectorAll('.web_media--image');

	function webStoryShift(entries, observer) {
		entries.forEach((entry) => {
			const index = +entry.target.getAttribute('data-index');

			if (entry.isIntersecting) {
				entry.target.classList.add('current');
				webMediaItems.forEach(item => item.classList.remove('current'));
				document.querySelector(`.web_media--image[data-index="${index - 1}"]`)?.classList.add('prev');
				document.querySelector(`.web_media--image[data-index="${index}"]`)?.classList.add('current');
				document.querySelector(`.web_media--image[data-index="${index + 1}"]`)?.classList.add('next');
				document.querySelector(`.web_media--image[data-index="${index}"]`)?.classList.remove('next', 'prev');

				if (entry.target.classList.contains('even')) {
					webMedia.classList.remove('odd');
					webMedia.classList.add('even');
				}
				else {
					webMedia.classList.remove('even');
					webMedia.classList.add('odd');
				}
			}
			else {
				entry.target.classList.remove('current');
			}
		});
	}

	webStoryItems.forEach((item, index) => {
		item.setAttribute('data-index', index);
		index % 2 === 0 ? item.classList.add('odd') : item.classList.add('even');
	});
	webMediaItems.forEach((item, index) => {
		item.setAttribute('data-index', index);
		index % 2 === 0 ? item.classList.add('odd') : item.classList.add('even');
	})

	function startObserver() {
		if (window.innerWidth > CSSVAR.breakpointMobile) {
			webStoryOptions.threshold = .5;
			webStoryOptions.rootMargin = `0px`;
			webObserver = new IntersectionObserver(webStoryShift, webStoryOptions);
		}
		else {
			webStoryOptions.threshold = 1;
			webStoryOptions.rootMargin = `-${webMediaHeight}px 0px 0px 0px`;
			webObserver = new IntersectionObserver(webStoryShift, webStoryOptions);
		}
		webStoryItems.forEach((item, index) => {
			webObserver.observe(item);
		})
	}
	startObserver();


	let webResize;
	window.addEventListener('resize', () => {
		clearTimeout(webResize);
		webResize = setTimeout(() => {
			webObserver.disconnect();
			startObserver();
		}, 100)
	})
}


export function setWebMediaOffset() {
	document.querySelector('.web_media').style.setProperty('--translate-offset', document.querySelector('.web_media--dummy').getBoundingClientRect().width + 'px');
}


export function initWebButtonNav() {
	const webSection = document.querySelector('#section_web .section_scroll');

	webSection.addEventListener('scroll', () => {
		if (webSection.scrollTop >= webSection.scrollHeight - window.innerHeight - 1) {
			document.querySelector('.web_footer .button_arrow').classList.remove('inactive');
			document.querySelector('.web_header .arrow_up').classList.remove('inactive');
		}
		else {
			document.querySelector('.web_footer .button_arrow').classList.add('inactive');
			document.querySelector('.web_header .arrow_up').classList.add('inactive');
		}

		if (webSection.scrollTop > window.innerHeight / 3) {
			document.querySelector('.web_header .arrow_down').classList.add('inactive');
		}
		else {
			document.querySelector('.web_header .arrow_down').classList.remove('inactive');
		}

		if (webSection.scrollTop > window.innerHeight / 2) {
			document.querySelector('.web_main').classList.remove('waiting');
		}
	});

	document.querySelector('.web_header .arrow_down').addEventListener('click', () => {
		document.querySelector('#section_web .section_scroll').scrollTo({ top: window.innerHeight, behavior: 'smooth' });
	});

	document.querySelector('.web_footer .button_arrow').addEventListener('click', () => {
		const blackout = document.querySelector('#section_web .web_blackout');

		blackout.classList.add('active');

		setTimeout(() => {
			document.querySelector('#section_web .section_scroll').scrollTo({ top: 0, behavior: 'instant' });
			resetWebMedia();
			document.querySelectorAll('.web_header .outline_text').forEach(element => {
				element.classList.remove('flicker');

				window.requestAnimationFrame(() => {
					element.classList.add('flicker');
				})
			});
		}, CSSVAR.speedWebHeaderAnimation / 2);

		setTimeout(() => {
			blackout.classList.remove('active');
		}, CSSVAR.speedWebHeaderAnimation * 1.5);
	});
}

export function resetWebMedia() {
	const webMediaContent = document.querySelector('.web_media--content');
	webMediaContent.classList.add('odd');
	webMediaContent.classList.remove('even');

	document.querySelector('.web_main').classList.add('waiting');

	document.querySelectorAll('.web_media--image').forEach((item, index) => {
		if (index === 0) {
			item.classList.add('current');
			item.classList.remove('next', 'prev');
		}
		else {
			item.classList.add('next');
			item.classList.remove('prev', 'current');
		}
	})
}

export function initWebShiftAnimation() {
	const webHeading = document.querySelector('.web_header .web_heading');

	wrapText(webHeading, 'big_letter');

	let itemArray = shuffle(Array.from(document.querySelectorAll('.web_header .big_letter')));

	itemArray.forEach((item, index) => {
		item.style.setProperty('--flicker-delay', index * 70 + Math.floor(Math.random() * 35) + CSSVAR.speedShift / 4 + 'ms');
		item.style.setProperty('--flicker-stretch', Math.floor(Math.random() * 70) + 'ms');
	})

	const webIntro = document.querySelector('.web_header .web_intro');
	wrapText(webIntro, 'small_letter invisible');
}

export function shuffleWebLetters() {
	if (document.body.matches('.target_web.inside') && !window.visitedWeb) {
		const letters = document.querySelectorAll('.web_header .small_letter');
		const bigCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		const smallCharacters = 'abcdefghijklmnopqrstuvwxyz';

		letters.forEach((letter, index) => {
			const originalLetter = letter.innerHTML;
			let i = 0;

			const interval = setInterval(() => {
				if ( (/[A-Z]/).exec(originalLetter) ) {
					letter.innerHTML = bigCharacters[Math.floor(Math.random() * bigCharacters.length)];
				}
				else {
					letter.innerHTML = smallCharacters[Math.floor(Math.random() * smallCharacters.length)];
				}
				i++;
				if (i > index) {
					letter.classList.remove('invisible');
				}
				if (i > index + 30) {
					letter.innerHTML = originalLetter;
					clearInterval(interval);
				}
			}, 20);
		})
		window.visitedWeb = true;
	}
}

